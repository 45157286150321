import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { GraficosSlice } from './graficosSlice.types';

export const initialState: GraficosSlice = {
  data: [],
}

const graficosSlice = createSlice({
  name: 'graficos',
  initialState,
  reducers: {
    storeGraficos: (
      state,
      { payload }: PayloadAction<GraficosSlice>
    ) => {
      state.data = payload.data;
    },
  }
});

export default graficosSlice;
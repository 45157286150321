import { defaultMeta } from '../../../constants/defaultMeta';
import actions from './actions';
import { NewsStateProps } from './types';

const initialState: NewsStateProps = {
  news: {
    data: [],
    meta: defaultMeta,
  }
};

export default function newsReducer(state = initialState, action: any) {
  switch (action.type) {
    case actions.FETCH_NEWS_SUCCESS:
      return {
        ...state,
        news: {
          data: action.payload
            ? action.payload.data
            : initialState.news.data,
          meta: action.payload
            ? action.payload.meta
            : initialState.news.meta,
        },
      };
    case 'LOGOUT_SUCCESS':
      return initialState;
    default:
      return state;
  }
}

import actions from './actions';
import { MeuPlano, PlansStateProps } from './types';

const initialState: PlansStateProps = {
  plansList: {
    data: {},
    meta: {},
  },
  opcoesPlanos: {},
  historicPlans: {
    data: [],
    meta: {},
  },
  plan: {} as MeuPlano,
  plansGroupsList: {
    data: {
      planos: undefined,
      grupos: undefined,
    },
    meta: {},
  },
};

export default function plansReducer(state = initialState, action: any) {
  switch (action.type) {
    case actions.FETCH_PLANS_LIST_SUCCESS:
      return {
        ...state,
        plansList: {
          data: action.payload ? action.payload.data : initialState.plansList.data,
          meta: action.payload ? action.payload.meta : initialState.plansList.meta,
        },
      };
    case actions.FETCH_MEU_PLANO_SUCCESS:
    case actions.FETCH_PLAN_SUCCESS: {
      return { ...state, plan: { ...action.payload.data } };
    }
    case actions.FETCH_PLANOS_E_GRUPOS_SUCCESS:
      if (action.payload.planos) {
        return {
          ...state,
          opcoesPlanos: action.payload.planos,
        };
      }

    case actions.FETCH_HISTORICO_PLANOS_SUCCESS:
      if (action.payload.meta.current_page > 1) {
        return {
          ...state,
          historicPlans: {
            data: [...state.historicPlans.data, ...action.payload.data],
            meta: state.historicPlans.meta,
          },
        };
      }
      return { ...state, historicPlans: action.payload };

    case actions.FETCH_PLANSGROUPS_SUCCESS:
      return {
        ...state,
        plansGroupsList: {
          data: action.payload
            ? action.payload.data
            : initialState.plansGroupsList.data,
          meta: action.payload
            ? action.payload.meta
            : initialState.plansGroupsList.meta,
        },
      };
    case 'LOGOUT_SUCCESS':
      return initialState;
    default:
      return state;
  }
}
